const pcHome = () => import('@/views/pc/home/index.vue'); // 首页+
const socialMarketing = () => import('@/views/pc/socialMarketing/index.vue'); // 社会化营销
const haxPlatform = () => import('@/views/pc/haxPlatform/index.vue'); // HAX平台
const selCases = () => import('@/views/pc/selCases/index.vue'); // 精选案例
const library = () => import('@/views/pc/library/index.vue'); // 媒体库
const about = () => import('@/views/pc/about/index.vue'); // 关于
export default [
  {
    path: '/pc/home',
    component: pcHome,
    name: 'pcHome'
  },
  {
    path: '/pc/socialMarketing',
    component: socialMarketing,
    name: 'socialMarketing'
  },
  {
    path: '/pc/haxPlatform',
    component: haxPlatform,
    name: 'haxPlatform'
  },
  {
    path: '/pc/selCases',
    component: selCases,
    name: 'selCases'
  },
  {
    path: '/pc/library',
    component: library,
    name: 'library'
  },
  {
    path: '/pc/about',
    component: about,
    name: 'about'
  }
];
